import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ShowMenuButton from './ShowMenuButton/ShowMenuButton';
import imgsrc from '../../Icons/logo_df.svg'
import icapImg from '../../Icons/icap-logo.png'
import LanguageSelector from './LanguageSelector/LanguageSelector';
import './Title.css';

const Title = () => {

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initialize on component mount
    handleResize();

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  })

  return (
    <div>
      {isMobile
        ? <div className="title">
          <div>
            <Link to="/" className="logo-link"><Image src={imgsrc} alt="Date firme" className="title-logo" /></Link>
            <ShowMenuButton />
            <LanguageSelector />
          </div>
          <a href='https://www.icapcrif.com/' target='_blank'><Image src={icapImg} className='icap-img' /></a>
        </div>
        : <div className="title">
          <Link to="/" className="logo-link"><Image src={imgsrc} alt="Date firme" className="title-logo" /></Link>
          <ShowMenuButton />
          <LanguageSelector />
          <a href='https://www.icapcrif.com/' target='_blank'><Image src={icapImg} className='icap-img' /></a>
        </div>
      }
    </div>
  )

};

export default Title;

import React from 'react'
import './ConfirmModal.css'
import ModalsActions from '../../../actions/ModalsActions';
import SimpleButton from '../../Reusable/SimpleButton/SimpleButton';

const ConfirmModal = ({message, onConfirm}) => {

  const handleCloseClick = () => {
    ModalsActions.hideModal();
  }

  return (
    <div className='modal-dialog topConfirmModal'>
      <div className="modal-content confirmModal">
        <div className="row closeBtn">
          <button type="button" className="modals-close-button close" onClick={(event) => handleCloseClick(event)}>&times;</button>
        </div>
        <p>{message}</p>
        <div className='confirmButtons'>
          <SimpleButton styleCss='noConfirm' text="Nu, intoarce-te"/>
          <SimpleButton text="Da, continua" onClick={onConfirm}/>
        </div>
      </div>
    </div>
  )
}

export default ConfirmModal
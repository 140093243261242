import React from 'react'
import './EmailContent.css'
import ModalsActions from '../../../actions/ModalsActions';
import DOMPurify from 'dompurify';

const EmailContent = ({content}) => {

  const handleCloseClick = () => {
    ModalsActions.hideModal();
  }

  const sanitizedHtml = DOMPurify.sanitize(content)

  return(
    <div className='modal-dialog emailContentModal'>
      <div className="modal-content">
        <div className="row closeBtn">
          <button type="button" className="modals-close-button close" onClick={(event) => handleCloseClick(event)}>&times;</button>
        </div>
        <p><div /></p>
        <p dangerouslySetInnerHTML={{__html: sanitizedHtml}}/>
      </div>
    </div>
  )
}


export default EmailContent
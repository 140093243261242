import React, { Component } from 'react';

import LeftMenuActions from '../../../../actions/LeftMenuActions';

import './ShowMenuButton.css';

/**
 * The button that displays the hidden menu for small screens.
 */
class ShowMenuButton extends Component {
	handleClick = () => {
	    LeftMenuActions.show();
	}

	render() {
	    return (
	        <button className="btn btn-xs btn-no-bg hidden-lg hidden-md pull-left show-menu-parent" onClick={this.handleClick}> 
	            <span className="show-menu-button glyphicon glyphicon-menu-hamburger" />
	        </button>
	    );
	}
}

export default ShowMenuButton;